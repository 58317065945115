.NewContest form {
  padding-bottom: 15px;
}

.NewContest form textarea {
  height: 300px;
  font-size: 24px;
}

td.winner {
  background-color: lightgreen;
}

a.winner {
  background-color: lightgreen;
}

td.bigWinner {
  background-color: mediumseagreen;
}

a.bigWinner {
  background-color: mediumseagreen;
}

td.loser {
  background-color: lightpink;
}

a.loser {
  background-color: lightpink;
}

td.bigLoser {
  background-color: palevioletred;
}

a.bigLoser {
  background-color: palevioletred;
}

.ToggleNotSelected {
  background-color: gray !important;
}
